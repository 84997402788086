import BigPicture from 'bigpicture';

const galleryExemple = () => {
  const imageLinks = document.querySelectorAll('#img_container a');
  for (var i = 0; i < imageLinks.length; i++) {
    imageLinks[i].addEventListener('click', function(e) {
      e.preventDefault()
      BigPicture({
        el: e.target,
        gallery: '#img_container',
      })
    })
  }
}

galleryExemple();