import ScrollReveal from 'scrollreveal';

const sr = ScrollReveal();

sr.reveal("#card-exte", {
  reset: false,
  duration: 1200,
  scale: 0.6
});

sr.reveal("#card-exteprem", {
  reset: false,
  duration: 1200,
  scale: 0.6
});

sr.reveal("#card-extesanso", {
  reset: false,
  duration: 1200,
  scale: 0.6
});

sr.reveal("#card-nett", {
  reset: false,
  duration: 1200,
  scale: 0.6
});

sr.reveal("#card-nettprem", {
  reset: false,
  duration: 1200,
  scale: 0.6
});

sr.reveal("#img_container", {
  reset: false,
  duration: 1200,
  scale: 0.6
});

sr.reveal("#contactForm", {
  reset: false,
  duration: 1200,
  scale: 0.6
});